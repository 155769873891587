import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }
  static targets = [
    "content"
  ]

  connect() {
    this.createUser()
  }

  async createUser() {
    const request = new FetchRequest('post', this.urlValue)
    const response = await request.perform()
    if (response.ok) {
    } else {
      localStorage.setItem('count',  +(localStorage.getItem('count'))+ 1)
      if (+(localStorage.getItem('count')) < 3) {
        setTimeout(function(){
          location.reload();
        }, 10000);
      } else {
        this.contentTarget.classList = 'mx-4 d-flex flex-column align-items-start'
        this.contentTarget.innerHTML = "Something went wrong. <br><a class='link link--live' href='mailto:help@acupoll.com'>Click here to message a moderator</a>"
        localStorage.setItem('count', 0)
      }
    }
  }
}
