import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    'questionAnsweredHeader',
    'questionAnsweredCount',
    'segmentSource',
    'statusSource',
    'filterable'
  ]

  static values = {
    lowerCaseSegmentTerm: {
      type: String,
      default: ''
    },
    lowerCaseStatusTerm: {
      type: String,
      default: ''
    }
  }

  toggleDisplay(e) {
    $(this.questionAnsweredHeaderTarget).toggleClass('d-none')
    $(this.questionAnsweredCountTargets).toggleClass('d-none')

    if (e.currentTarget.value == 'true')
      $(e.currentTarget).val(false).text("Hide Q's Answered")
    else
      $(e.currentTarget).val(true).text("Show Q's Answered")
  }

  filter(event) {
    this.lowerCaseSegmentTerm = this.segmentSourceTarget.value.toLowerCase()
    if (this.hasStatusSourceTarget) {
      this.lowerCaseStatusTerm = this.statusSourceTarget.value.toLowerCase()
    }

    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-survey-respondent-key").toLowerCase()

      if (i > 25) {
        // TODO WHAT
      }

      if (this.hasStatusSourceTarget) {
        el.classList.toggle("d-none", !(filterableKey.includes( this.lowerCaseSegmentTerm ) && filterableKey.includes( this.lowerCaseStatusTerm )) )
      } else {
        el.classList.toggle("d-none", !filterableKey.includes( this.lowerCaseSegmentTerm ) )
      }
    })
  }
}
