import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    'answer',
    'open',
    'openTitle'
  ]

  static values = {
    answered: {
      type: Boolean,
      default: false
    },
    lastWaitingRoomQuestion: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.setOpen()
    if (this.answeredValue) {
      window.answeredAlready = true
      this.setFailsafe()
    }
  }

  setFailsafe() {
    let refresh_time = 180000
    if (this.lastWaitingRoomQuestionValue) {
      refresh_time = 300000
    }
    setTimeout(function(){
      this.enableRefreshNow()
    }.bind(this), refresh_time)
  }

  enableRefreshNow() {
    document.getElementById('refresh-later').classList.add('d-none')
    document.getElementById('refresh-now').classList.remove('d-none')
    // document.getElementById('refresh-now').hide
  }

  setOpen() {
    if (!this.hasOpenTarget) {
      return
    }

    var checked = this.answerTargets.find(answer => answer.checked)

    if (checked != null) {
      this.openTitleTarget.innerHTML = document.querySelector(`label[for="${checked.id}"]`).innerText
      this.openTarget.classList.remove('d-none')
    } else {
      this.openTarget.classList.add('d-none')
    }
  }
}
