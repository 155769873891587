import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    currentQuestionId: {
      type: Number,
      default: 1
    },
    startTime: String,
    endTime: String
  };

  connect() {
    this.setStartTime();
    this.updateTime();
    if (!this.endTimeValue) {
      this.startTimer();
    }
  }

  setStartTime() {
    if (!localStorage.getItem('currentquestionid') || !localStorage.getItem('starttime') || this.startTimeValue != localStorage.getItem('starttime') || this.currentQuestionIdValue != localStorage.getItem('currentquestionid')) {
      if (this.hasStartTimeValue) {
        localStorage.setItem('starttime', this.startTimeValue);
      }
      localStorage.setItem('currentquestionid', this.currentQuestionIdValue);
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  updateTime() {
    const now = this.hasEndTimeValue ? new Date(this.endTimeValue) : new Date();
    const startTime = localStorage.getItem('starttime') ? new Date(localStorage.getItem('starttime')) : new Date();

    const elapsedSeconds = Math.floor((now - startTime) / 1000);

    const hours = Math.floor(elapsedSeconds / 3600);
    const minutes = Math.floor((elapsedSeconds % 3600) / 60);
    const seconds = elapsedSeconds % 60;

    this.element.textContent = this.stringifyTime(hours, minutes, seconds);
  }

  pad(number) {
    return number.toString().padStart(2, '0');
  }

  stringifyTime(hours, minutes, seconds) {
    if (hours > 0) {
      return `${hours}:${this.pad(minutes)}:${this.pad(seconds)}`
    } else {
      return `${minutes}:${this.pad(seconds)}`;
    }
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
