import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToSelected();

    this.observeDomChanges();
  }

  disconnect() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  scrollToSelected() {
    const container = this.element;
    const liveControls = container.querySelector("#live-controls");
    const selectedItem = container.querySelector(".nav-item--selected");

    if (selectedItem) {
      const containerRect = container.getBoundingClientRect();
      const liveControlsRect = liveControls.getBoundingClientRect();
      const selectedItemRect = selectedItem.getBoundingClientRect();

      // Check if the selected item is fully visible in the container
      const isFullyVisible =
        selectedItemRect.top >= containerRect.top &&
        selectedItemRect.bottom <= containerRect.bottom;

      if (!isFullyVisible) {
        // Scroll so the selected item is visible, accounting for liveControls
        container.scrollTop += selectedItemRect.top - liveControlsRect.top - containerRect.top;
      }
    }
  }

  observeDomChanges() {
    const container = this.element;

    this.mutationObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" || mutation.type === "subtree") {
          this.scrollToSelected();
          break;
        }
      }
    });

    this.mutationObserver.observe(container, {
      childList: true, // Watch for direct children changes
      subtree: true,   // Watch for changes in descendants
    });
  }
}
// import { Controller } from "@hotwired/stimulus";

// export default class extends Controller {
//   connect() {
//     this.scrollToSelected();

//     this.observeDomChanges();
//   }

//   disconnect() {
//     if (this.mutationObserver) {
//       this.mutationObserver.disconnect();
//     }
//   }

//   scrollToSelected() {
//     // Ensure the container is properly targeted
//     const container = this.element
//     const liveControls = container.querySelector("#live-controls")
//     let selectedItem = container.querySelector(".nav-item--selected");

//     if (selectedItem) {
//       const containerRect = container.getBoundingClientRect();
//       const liveControlsRect = liveControls.getBoundingClientRect();
//       const selectedItemRect = selectedItem.getBoundingClientRect();

//       // Check if the selected item is outside the visible area
//       if (selectedItemRect.top > containerRect.top) {
//         container.scrollTop = selectedItemRect.top - liveControlsRect.top - containerRect.top;
//       }
//     }
//   }

//   observeDomChanges() {
//     const container = this.element;

//     this.mutationObserver = new MutationObserver((mutationsList) => {
//       for (const mutation of mutationsList) {
//         if (mutation.type === "childList" || mutation.type === "subtree") {
//           this.scrollToSelected();
//           break;
//         }
//       }
//     });

//     this.mutationObserver.observe(container, {
//       childList: true, // Watch for direct children changes
//       subtree: true,   // Watch for changes in descendants
//     });
//   }
// }
// import { Controller } from "@hotwired/stimulus";

// export default class extends Controller {
//   observeMutations() {
//     const observer = new MutationObserver(() => this.scrollToSelected());
//     observer.observe(this.element, { childList: true, subtree: true });
//   }

//   connect() {
//     this.scrollToSelected();
//   }

//   scrollToSelected() {
//     // Ensure the container is properly targeted
//     const container = this.element
//     const liveControls = container.querySelector("#live-controls")
//     const selectedItem = container.querySelector(".nav-item--selected");

//     if (selectedItem) {
//       const containerRect = container.getBoundingClientRect();
//       const liveControlsRect = liveControls.getBoundingClientRect();
//       const selectedItemRect = selectedItem.getBoundingClientRect();

//       // Check if the selected item is outside the visible area
//       if (selectedItemRect.top > containerRect.top) {
//         container.scrollTop = selectedItemRect.top - liveControlsRect.top - containerRect.top;
//       }
//     }
//   }
// }
