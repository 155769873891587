import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Connected to the turbo append channel.")

    this.listenForTurboAppend()

    // const trigger = new CustomEvent("turbo-appended");
    // window.dispatchEvent(trigger);
  }

  listenForTurboAppend() {
    document.addEventListener("turbo:before-stream-render", this.handleStreamRender.bind(this))
  }

  handleStreamRender(event) {
    const turboStreamElement = event.target

    // Check if the action is "append"
    const action = turboStreamElement.getAttribute("action")
    if (action === "append") {
      // Only trigger this logic when action is append
      const trigger = new CustomEvent("turbo-appended");
      window.dispatchEvent(trigger);
      // console.log("Turbo stream append triggered!")
      
      // Your custom logic here
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-stream-render", this.handleStreamRender.bind(this))
  }
}
