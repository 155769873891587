import { Controller } from "stimulus"

export default class extends Controller {

  errorMessage = {
    'size': 'Invalid image size, must be less then 5MB',
    'count': 'Can not select multiple images',
    'extension': 'Invalid file type, File type must be png/jpeg/gif'
  }

  static targets = [
    'imageField',
    'currentImage',
    'dragMessage',
    'dragError',
    'dragImage'
    ]

  static values = {
    sourceId: String,
    allowUpload: Boolean,
  }

  setImageRecord(e){
    this.allowUploadValue = true
    this.dragErrorTarget.innerText = ''
    this.sourceIdValue = e.currentTarget.attributes['source-id'].value
    const files = $(`#${this.sourceIdValue}`)[0].files
    if(files.length > 0){
      this._showImage(files)
      this.imageFieldTarget.files = this._fileListItems(files)
    }else{
      this._showPreviousImage(e.currentTarget)
    }
  }

  imageDropped(e){
    e.preventDefault();
    this.dragErrorTarget.innerText = ''
    if(this.imageFieldTarget.files.length > 0) {
      this.dragErrorTarget.innerText = this.errorMessage['count']
      return
    }

    const files = e.dataTransfer.files
    if(this._checkFile(files)){
      this.imageFieldTarget.files = this._fileListItems(files)
      this._showImage(files)
    }
  }

  browseImage(){
    if(this.allowUploadValue)
      this.imageFieldTarget.click()
  }

  imageSelected(e){
    this.dragErrorTarget.innerText = ''
    if(this._checkFile(e.currentTarget.files))
      this._showImage(e.currentTarget.files)
  }

  updateImage(){
    const imageField = document.getElementById(this.sourceIdValue)
    imageField.files = this._fileListItems(this.imageFieldTarget.files)
    this.closeImageModal()
  }

  closeImageModal(){
    this.dragErrorTarget.innerText = ''
    $(this.dragImageTarget).html('')
    $(this.currentImageTarget).html('')
    this.imageFieldTarget.value = ''
    $(this.dragMessageTarget).show()
  }

  removeImage(e){
    this.imageFieldTarget.value = this.dragImageTarget.innerHTML = this.dragErrorTarget.innerText = ''
    $(this.dragMessageTarget, this.dragErrorTarget).show()
    this.allowUploadValue = true
  }

  _checkFile(files){
    return (this.checkFilesCount(files) && this.checkFileExtension(files) && this.checkFileSize(files))
  }

  checkFilesCount(files){
    const check = files.length == 1
    if(!check)
      this.dragErrorTarget.innerText = this.errorMessage['count']

    return check
  }

  checkFileSize(files){
    const check = (files[0].size / (1024 * 1024)) < 5
    if(!check)
      this.dragErrorTarget.innerText = this.errorMessage['size']

    return check
  }

  checkFileExtension(files){
    const check = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(files[0].type)
    if(!check)
      this.dragErrorTarget.innerText = this.errorMessage['extension']

    return check
  }

  _showImage(files){
    const file = files[0]
    $(this.dragMessageTarget, this.dragErrorTarget).hide()
    $(this.dragImageTarget).html(this._createImageForShow(file))
    this.allowUploadValue = false
  }

  _createImageForShow(file){
    const url = URL.createObjectURL(file);
    return `<div class='d-flex flex-column'>
              <img src='${url}' alt='${file.name}' height='100%' />
              <button class='btn btn-danger mt-3' data-action='click->image-upload#removeImage'>remove</button>
            </div`
  }

  allowDrop(e){
    e.preventDefault();
  }

  _fileListItems(files){
    var obj = new ClipboardEvent("").clipboardData || new DataTransfer()
    for (var i = 0, len = files.length; i<len; i++)
      obj.items.add(files[i])

    return obj.files
  }

  _showPreviousImage(source){
    const name = source.attributes['target-image-name']
    const url = source.attributes['target-image-url']
    if(name){
      const img = `<img src='${url.value}' alt='${name.value}' height='80px' />`
      const imgTitle = `<p>${name.value}</p>`
      $(this.currentImageTarget).html(img + imgTitle)
    }
  }
}
