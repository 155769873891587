/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("channels")
var $ = require('jquery')

import { Controller, Application } from "stimulus"

import "../controllers"

import { createConsumer } from "@rails/actioncable"

export default createConsumer()

import "@hotwired/turbo-rails"
import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

if (!window.scrollPositions) {
  window.scrollPositions = {};
}

function preserveScroll() {
  document.querySelectorAll("[data-preserve-scroll]").forEach((element) => {
    if (element.id) {
      window.scrollPositions[element.id] = element.scrollTop;
    }
  });
}

function restoreScroll(event) {
  document.querySelectorAll("[data-preserve-scroll]").forEach((element) => {
    if (element.id && window.scrollPositions[element.id] !== undefined) {
      element.scrollTop = window.scrollPositions[element.id];
    }
  });

  if (!event.detail.newBody) return;

  event.detail.newBody.querySelectorAll("[data-preserve-scroll]").forEach((element) => {
    if (element.id && window.scrollPositions[element.id] !== undefined) {
      element.scrollTop = window.scrollPositions[element.id];
    }
  });
}

// Turbo lifecycle events
window.addEventListener("turbo:before-cache", preserveScroll);
window.addEventListener("turbo:before-render", restoreScroll);
window.addEventListener("turbo:render", restoreScroll);
