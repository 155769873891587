// app/javascript/controllers/chart_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    updateInterval: Number,
    colors: Array,
    seriesData: Array,
    seriesMeans: Array,
    seriesNames: Array,
    answerLabels: Array,
    datafieldLabels: Array,
    qtype: String
  }

  connect() {
    if (this.qtype == "5") {
      return
    }
    this.chart = null;
    this.initializeChart();
    // this.startAutoUpdate();
  }

  disconnect() {
    this.stopAutoUpdate();
  }

  initializeChart() {
    const qtype = this.qtypeValue;
    if (["1", "8"].includes(qtype) || (qtype == "2" && this.datafieldLabelsValue.length == 1)) {
      this.initPercentChart();
      this.buildChart(this.seriesDataValue, this.answerLabelsValue);
    } else if (["3"].includes(qtype)) {
      this.initPercentChart();
      this.buildChart(this.seriesMeansValue, this.datafieldLabelsValue);
    } else if (["2"].includes(qtype)) {
      this.initMeanChart();
      this.buildChart(this.seriesMeansValue, this.datafieldLabelsValue);
    } else if (qtype == "6") {
      this.chart = this.element;
      this.prepare_acutext();
      this.adjust_acutext();
    }
  }

  initMeanChart() {
    this.chart = Highcharts.chart(this.element, {
      title: false,
      chart: {
        type: 'column',
        backgroundColor: null,
        marginTop: 0,
        marginBottom: 100,
        height: 600,
        events: {
          load: () => this.scheduleUpdate()
        }
      },
      legend: {
        align: 'left'
      },
      xAxis: {
        labels: {
          useHTML: true,
          overflow: 'allow',
          align: 'center',
          style: {
            textOverflow: 'none',
            maxHeight: '100px',
            overflowY: 'auto',
            fontSize: '13px',
            fontWeight: 'bold'
          }
        }
      },
      yAxis: {
        min: 0,
        softMax: 10,
        title: { text: "Mean" },
        labels: { enabled: false }
      },
      credits: { enabled: false },
      tooltip: {
        shared: true,
        useHTML: true,
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                     '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>'
      },
      plotOptions: {
        column: {
          pointPadding: 0.05,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<b style="font-size:20px">${this.point.y.toFixed(1)}</b>`;
            }
          }
        }
      }
    });
  }

  initPercentChart() {
    this.chart = Highcharts.chart(this.element, {
      title: false,
      chart: {
        type: 'column',
        marginTop: 0,
        marginBottom: 100,
        height: 600,
        events: {
          load: () => this.scheduleUpdate()
        }
      },
      xAxis: {
        labels: {
          useHTML: true,
          align: 'center',
          style: {
            textOverflow: 'none',
            maxHeight: '100px',
            overflowY: 'auto',
            fontSize: '13px',
            fontWeight: 'bold'
          }
        }
      },
      yAxis: {
        min: 0,
        softMax: 100,
        title: { text: "% Selecting", align: 'middle' },
        labels: { enabled: false }
      },
      credits: { enabled: false },
      tooltip: {
        shared: true,
        useHTML: true,
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                     '<td style="padding:0"><b>{point.y:.0f}%</b></td></tr>'
      },
      plotOptions: {
        column: {
          pointPadding: 0.05,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<b style="font-size:20px">${(this.point.y).toFixed(0)}%</b>`;
            }
          }
        }
      }
    });
  }

  buildChart(series, labels) {
    for (var i=0;i<series.length;i++){
      this.chart.xAxis[0].setCategories(labels, false);
      this.chart.addSeries({
        color: this.colorsValue[i],
        name: this.seriesNamesValue[i],
        data: series[i]
      });
    }
  }

  scheduleUpdate() {
    setTimeout(() => this.updateChart(), this.updateIntervalValue || 3000);
  }

  updateChart() {
    $.getScript(`${window.location.pathname.replace('current', 'build')}`, () => {
      this.chart.redraw();
    });
  }

  startAutoUpdate() {
    this.updateIntervalId = setInterval(() => this.updateChart(), this.updateIntervalValue || 3000);
  }

  stopAutoUpdate() {
    if (this.updateIntervalId) {
      clearInterval(this.updateIntervalId);
    }
  }

  clearSeries() {
    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }
  }

  randomUpdate(index) {
    const series = this.chart.series[index];
    const x = Math.floor(Math.random() * 6);
    const point = series.data[x];
    const newValue = point.y - Math.random() * 2 + Math.random() * 2;
    if (newValue > 0) {
      point.update(newValue);
    }
  }

  prepare_acutext(){
    this.chart.innerHTML = "";
    for (var i=0;i<this.datafieldLabelsValue.length - 1;i++){
      var word = this.datafieldLabelsValue[i];
      var output_AT = this.chart.innerHTML;
      this.chart.innerHTML = (output_AT + "<span class='acutext_word' id='word_"+i+"' style='font-size:20px; color: black'> "+word+"&nbsp</span>");
    }
  }

  adjust_acutext(){
    for (var i=0;i<this.datafieldLabelsValue.length - 1;i++){
      var size = ((this.seriesMeansValue[0][i]-0)*3+1)*20+"px";
      $("#word_"+i).animate({fontSize: size}, 500);
    }
  }

  // $('.area').mousedown(function() {
  //   c = $(this).attr("area_index");
  //   $(".checkBox[data-group="+c+"]").click();

  //   acutext_refresh();
  // })

  // refresh_acutext() {
  //   $('.area').each(function() {
  //     c = $(this).attr("area_index");
  //     selected = $(".checkBox[data-group="+c+"]").attr('value');
  //     if(selected == 'true'){
  //       $(this).addClass("area_HL");
  //     } else {
  //       $(this).removeClass("area_HL");
  //     }
  //   })
  // }

}
