// src/controllers/datetimepicker_controller.js
import { Controller } from "stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    flatpickr('.datetimepicker', {
      enableTime: true,
      altInput: true,
      wrap: true,
      altFormat: "F j, Y @ h:i K",
      dateFormat: "Y-m-d H:i",
    });
  }
}
