// src/controllers/drag_controller.js
import { Controller } from "stimulus"
import { Sortable, MultiDrag } from 'sortablejs'

export default class extends Controller {
  static values = { url: String, refreshInterval: Number }

  connect() {
    try {
      Sortable?.mount(new MultiDrag())
    } catch (error) {
      console.log('sortable error: ' + error)
    }

    this.sortable = Sortable.create(this.element, {
      multiDrag: true,
      handle: '.drag-handle',
      onUpdate: this.end.bind(this),
      selectedClass: "bg-warning"
    });
  }

  disconnect() {
    this.sortable.destroy()
  }

  end(event) {
    let all_items = this.getAllItems(event)
    this.element.classList.add('processing')
    this.updatePosition(all_items, this.element)
  }

  getAllItems(event) {
    let items = []
    for (let item of event.to.children) {
      items.push({id: item.dataset.id, position: Sortable.utils.index(item, '>*') + 1})
    }
    return items
  }

  updatePosition(data, element) {
    Rails.ajax({
      url: this.data.get("url"),
      type: 'POST',
      data: JSON.stringify(data),
      success: function() {
        element.classList.remove('processing')
      }
    });
  }
}
